.about {
  padding: 4rem 0 3rem 0;
  text-align: center;
  background-image: url('../images/notes.png');
  background-repeat: no-repeat;
  background-position: center center;

  @include tablet {
    padding: 6rem 0 5rem 0;
  }

  @include widescreen {
    padding: 7rem 0;
  }
}

.about__headline {

  @include widescreen {
    margin-bottom: 3.5rem;
  }
}

.about__text {
  color: $gray-color1;

  @include widescreen {
    font-size: 2.5rem;
    margin-bottom: 4rem;
  }
}

.about__img {
  margin: auto;
  width: 15rem;
  margin-bottom: 2rem;

  @include tablet {
    width: 20rem;
  }

  @include desktop {
    width: 23rem;
  }

  @include widescreen {
    width: 27rem;
  }
}

/*--SPECIFIC MEDIA QUERIES--*/

@media (min-width: 1921px) {

  .about {
    background-size: cover;
  }
}