.box {
  position: relative;
  padding: 5rem 2rem 2.5rem 2rem;
  border: 0.1rem solid $gray-color3;
  background-color: #fff;
  font-size: 1.3rem;
  margin-bottom: 3rem;

  p {
    line-height: 2;
  }

  &::before, &::after {
    @include pseudo;
    width: 19rem;
    height: 0.2rem;
    left: 50%;
    transform: translateX(-50%);
    background-color: $gold-color1;
  }

  &::before {
    top: -0.1rem;
  }

  &::after {
    bottom: -0.1rem;
  }

  @include tablet {
    height: 100%;
    margin-bottom: 0;
  }

  @include desktop {
    padding: 5rem 3rem 2.5rem 3rem;
  }
}

.box__headline {
  position: relative;
  font-size: 2.5rem;
  padding-top: 4.4rem;
  margin-bottom: 2.3rem;

  &::before {
    @include pseudo;
    width: 8rem;
    height: 1.3rem;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    @include image('../images/svg/lines.svg');
  }
}