.landing {
  position: relative;
  padding: 7rem 0;
  background-image: url('../images/landing-bg.jpg');
  background-size: cover;
  background-position: center center;
  overflow: hidden;

  @include small {
    padding: 12rem 0;
  }

  @include tablet {
    height: 50rem;
    padding: 0;
  }

  @include desktop {
    height: 100vh;
  }
}

.landing__container {
  width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  display: flex;
  justify-content: flex-end;

  @include tablet {
    align-items: center;
    height: 100%;
  }

  @include widescreen {
    margin: auto;
    width: 120rem;
    padding: 0 3rem;
  }
}

.landing__text {
  position: relative;
  color: $gold-color1;
  width: 70%;
  font-size: 1.8rem;
  padding-right: 1rem;

  .svg-icon {
    position: absolute;
    @include size(4rem);
    fill: $gold-color1;
    opacity: 0.23;
    left: -0.5rem;
    top: -1rem;

    @include tablet {
      @include size(5rem);
      top: -1.3rem;
    }

    @include desktop {
      @include size(10rem);
      top: -4.5rem;
      left: -2rem;
    }
  }

  @include tablet {
    width: 50rem;
    font-size: 2.2rem;
  }

  @include desktop {
    font-size: 2.6rem;
    width: 70rem;
  }

  @include widescreen {
    padding-right: 0;
    font-size: 3rem;
    width: 70rem;
  }
}

.landing__author {
  display: block;
  text-align: right;
  font-weight: bold;
  font-style: italic;
  padding-right: 3rem;

  @include tablet {
    font-size: 1.8rem;
    padding-top: 1.5rem;
  }

  @include desktop {
    font-size: 2.2rem;
  }

  @include widescreen {
    padding-right: 1.5rem;
    padding-top: 3rem;
  }
}

.landing__arrow {
  position: absolute;
  @include size(6rem);
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 50%);
  cursor: pointer;
  display: block;

  &::before {
    @include pseudo;
    @include size(100%);
    background-color: rgba(143,120,74,0.4);
    border-radius: 100%;
    left: 0;
    top: 0;
    transition: transform 350ms cubic-bezier(.42,.48,.11,1.95);
  }

  &::after {
    @include pseudo;
    @include size(100%);
    left: 0;
    top: 0;
    border-radius: 100%;
    background-color: #fff;
  }

  .svg-icon {
    position: absolute;
    @include size(1.8rem);
    fill: $gold-color1;
    left: 50%;
    top: 1.2rem;
    transform: translateX(-50%);
    z-index: 1;

    @include tablet {
      top: 1.5rem;
    }

    @include tablet {
      top: 2rem;
    }

    @include widescreen {
      top: 3.4rem;
    }
  }

  &:hover {

    &::before {
      transform: scale(1.5);
    }
  }

  @include tablet {
    @include size(7rem);
  }

  @include desktop {
    @include size(9rem);
  }

  @include widescreen {
    @include size(13rem);
  }
}

/*--SPECIFIC MEDIA QUERIES--*/

@media (min-width: 1600px) {

  .landing__container {
    width: 159rem;
  }

  .landing__text {
    width: 92rem;
    font-size: 4rem;

    .svg-icon {
      @include size(19rem);
      left: -1.7rem;
      top: -9.3rem;
    }
  }

  .landing__author {
    font-size: 2.5rem;
  }
}