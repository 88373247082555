h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  color: $gold-color1;
  font-weight: bold;
  margin-bottom: 2rem;
}

h1, .h1 {
  font-size: 3rem;

  @include tablet {
    font-size: 4rem;
  }

  @include widescreen {
    font-size: 5.8rem;
  }
}

h2, .h2 {
  font-size: 2.6rem;

  @include tablet {
    font-size: 3.5rem;
  }

  @include widescreen {
    font-size: 5rem;
  }
}

h3, .h3 {
  font-size: 2.2rem;

  @include tablet {
    font-size: 2.5rem;
  }
}

h4, .h4 {
  font-size: 2rem;
}

h5, .h5 {
  font-size: 1.8rem;
}

h6, .h6 {
  font-size: 1.6rem;
}

p, ul, ol {
  margin-bottom: 2.5rem;

  &+h1, &+.h1, &+h2, &+.h2, &+h3, &+.h3, &+h4, &+.h4, &+h5, &+.h5, &+h6, &+.h6  {
    padding-top: 1rem;
  }
}