.header {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #fff;
  height: 7rem;
  z-index: 999;
  box-shadow: 0 0 1rem rgba(0,0,0,0.05);

  &.header--menu-opened {

    .header__hamburger-level {

      &:nth-of-type(1) {
        transform: rotateZ(-45deg) translateY(0.4rem) translateX(-0.4rem);
      }

      &:nth-of-type(2) {
        opacity: 0;
      }

      &:nth-of-type(3) {
        transform: rotateZ(45deg) translateY(-0.7rem) translateX(-0.7rem);
      }
    }

    .header__nav {
      opacity: 1;
      pointer-events: auto;
    }

    .header__nav-list {
      transform: scale(1);
    }
  }

  @include tablet {
    background-color: transparent;
    position: absolute;
    box-shadow: none;
  }

  @include widescreen {
    height: auto;
  }
}

.header__container {
  display: flex;
  padding: 0 1.5rem;
  height: 100%;
  align-items: center;
  justify-content: flex-end;

  @include tablet {
    padding: 0 3rem;
  }

  @include widescreen {
    width: 120rem;
    margin: auto;
    padding-top: 9.5rem;
  }
}

.header__lang-switch {
  font-size: 1.6rem;
  font-weight: bold;
  display: inline-block;
  margin-right: 2rem;

  &:hover {
    text-decoration: none;
  }

  @include widescreen {
    margin: -0.7rem 0 0 2.5rem;
    padding: 0.7rem 0 0.7rem 2rem;
    border-left: 1px solid rgba(169,154,123,0.2);
  }
}

.header__logo {
  position: absolute;
  display: block;
  width: 9rem;
  height: 4.4rem;
  top: 1.6rem;
  left: 1.5rem;

  @include tablet {
    width: 12rem;
    height: 5.6rem;
    left: 3rem;
  }

  @include widescreen {
    width: 16rem;
    height: 7.6rem;
    top: 6rem;
    left: 8.6rem;
  }
}

.header__logo-img {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  object-fit: contain;
}

.header__hamburger {
  position: relative;
  width: 2.4rem;
  height: 1.7rem;

  @include widescreen {
    display: none;
  }
}

.header__hamburger-level {
  position: absolute;
  display: block;
  width: 100%;
  height: 0.2rem;
  background-color: $gold-color1;
  left: 0;
  transform-origin: center center;
  @include transition(300ms);

  &:nth-of-type(1) {
    top: 0;
  }

  &:nth-of-type(2) {
    top: 0.8rem;
  }

  &:nth-of-type(3) {
    top: 1.6rem;
  }
}

.header__nav {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  @include transition(300ms);

  @include widescreen {
    position: relative;
    width: auto;
    height: auto;
    left: auto;
    top: auto;
    background-color: transparent;
    opacity: 1;
    pointer-events: auto;
  }
}

.header__nav-list {
  margin: 0;
  padding: 0;
  transform: scale(0.9);
  @include transition(500ms);

  @include widescreen {
    transform: scale(1);
    display: flex;
  }
}

.header__nav-item {
  list-style-type: none;
  text-align: center;
  padding: 1rem 0;

  &+.header__nav-item {

    @include widescreen {
      margin-left: 15.5rem;
    }
  }

  @include widescreen {
    padding: 0;
  }
}

.header__nav-link {
  display: block;
  font-weight: bold;
  @include transition(200ms);
  color: $gold-color3;

  &:hover {
    text-decoration: none;

    @include widescreen {
      color: #000;

      &::before {
        width: 2.5rem;
      }
    }
  }

  &:active {
    transform: scale(0.9);
  }

  @include widescreen {
    padding-bottom: 0.7rem;
    position: relative;
  }

  &::before {

    @include widescreen {

      @include pseudo;
      left: 0;
      bottom: 0;
      width: 0;
      height: 0.2rem;
      background-color: $gold-color1;
      @include transition;
    }
  }
}

/*--SPECIFIC MEDIA QUERIES--*/

@media (min-width: 1600px) {

  .header__container {
    width: 159rem;
  }
}