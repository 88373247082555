@mixin small {
  @media (min-width: #{map-get($grid-breakpoints, sm)}) {@content;}
}

@mixin tablet {
  @media (min-width: #{map-get($grid-breakpoints, md)}) {@content;}
}

@mixin desktop {
  @media (min-width: #{map-get($grid-breakpoints, lg)}) {@content;}
}

@mixin widescreen {
  @media (min-width: #{map-get($grid-breakpoints, xl)}) {@content;}
}

@mixin transition ($duration: 200ms) {
  -webkit-transition: all $duration ease;
  -moz-transition: all $duration ease;
  -ms-transition: all $duration ease;
  -o-transition: all $duration ease;
  transition: all $duration ease;
}

@mixin pseudo {
  content: "";
  position: absolute;
  display: block;
}

@mixin image ($path) {
  background-image: url($path);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center center;
}

@mixin background-image ($path) {
  background-image: url($path);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
