.production {
  position: relative;

  &::before {
    @include pseudo;
    width: 100%;
    height: 70%;
    left: 0;
    bottom: 0;
    background-color: $gold-color5;
  }
}

.production__container {
  position: relative;
  background-color: $gold-color4;
  margin: 0 1.5rem;
  padding-top: 10rem;
  @include background-image('../images/production-bg.jpg');
  box-shadow: 0.5rem 0.5rem 7rem rgba(0,0,0,0.2);

  &::before {
    @include pseudo;
    @include image('../images/mockup-cd.png');
    width: 32rem;
    height: 16.5rem;
    left: 50%;
    transform: translateX(-50%);
    top: -8rem;

    @include tablet {
      width: 50rem;
      height: 25.8rem;
      top: -12rem;
    }

    @include widescreen {
      width: 96.8rem;
      height: 50rem;
      top: -23rem;
    }
  }

  @include tablet {
    padding-top: 16rem;
  }

  @include widescreen {
    margin: 0 5rem;
    padding-top: 28rem;
  }
}

.production__head {
  border-bottom: 0.1rem solid rgba(192,170,125,0.5);
  text-align: center;

  @include tablet {
    padding-bottom: 2.5rem;
  }
}

.production__body {
  padding-top: 2.5rem;
  padding-bottom: 1rem;
  font-size: 1.4rem;
  line-height: 2;

  @include tablet {
    padding-top: 4rem;
    padding-bottom: 2rem;
  }
}

.production__headline-1 {

  @include widescreen {
    font-size: 5.8rem;
  }
}

.production__headline-2 {
  font-weight: normal;

  @include widescreen {
    font-size: 3.6rem;
    margin-bottom: 3rem;
  }
}

.production__headline-3 {
  font-weight: normal;

  @include widescreen {
    font-size: 3rem;
  }
}