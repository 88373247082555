.footer {
  background-color: $gold-color5;
}

.footer__contacts {
  padding: 3rem 0;
  border-bottom: 1px solid $gold-color6;

  @include tablet {
    padding: 5rem 0;
  }
}

.footer__contacts-link {
  position: relative;
  font-size: 2rem;
  color: #000;
  padding-left: 3rem;
  margin-bottom: 0.5rem;
  display: inline-block;

  .svg-icon {
    position: absolute;
    @include size(1.5rem);
    left: 0;
    top: 1rem;
    fill: #000;

    @include tablet {
      @include size(1.9rem);
      top: 1.2rem;
    }
  }

  &:hover {
    color: #000;
  }

  @include tablet {
    font-size: 2.4rem;
    padding-left: 4rem;
  }
}

.footer__headline {
  color: $gold-color2;

  @include tablet {
    font-size: 3.5rem;
  }
}

.footer__text {
  color: $gold-color7;
  padding-top: 3rem;

  p {
    margin: 0;
  }

  @include desktop {
    padding: 1.2rem 4rem;
    border-left: 1px solid $gold-color6;
  }

  @include widescreen {
    padding: 1.2rem 7.5rem;
  }
}

.footer__credits {
  padding: 3rem 0 5rem 0;

  @include tablet {
    padding: 4rem 0 7rem 0;
  }
}

.footer__credits-container {
  display: flex;
  justify-content: center;
}

.footer__beneficio {
  position: relative;
  color: $gold-color7;
  padding-right: 7.5rem;
  display: inline-block;
  font-size: 1.2rem;

  .svg-icon {
    position: absolute;
    @include size(6.4rem);
    right: 0;
    top: 50%;
    margin-top: -3.1rem;
    fill: $gold-color8;
    @include transition;
  }

  &:hover {
    text-decoration: none;

    .svg-icon {
      fill: #000;
    }
  }
}