.gallery {
  margin: auto;
  position: relative;
  padding-bottom: 4rem;

  @include tablet {
    padding-bottom: 5rem;
  }

  @include desktop {
    width: 90rem;
    padding-bottom: 0;
  }

  @include widescreen {
    width: 112rem;
  }
}

.gallery__container {

}

.gallery__slide {
  padding-top: 55%;
}

.gallery__slide-img {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  object-fit: cover;
}

.gallery__navigation {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  z-index: 1;
  display: flex;

  @include desktop {
    left: -3.5rem;
    transform: none;
    bottom: 3.5rem;
    display: block;
  }
}

.gallery__prev, .gallery__next {
  width: 9rem;
  height: 6rem;
  cursor: pointer;
  position: relative;
  @include transition(100ms);

  .svg-icon {
    position: absolute;
    @include size(2.6rem);
    fill: #fff;
    top: 50%;
    left: 50%;
    transform: translateY(-50%);
    z-index: 2;
    opacity: 0.65;
    @include transition;
  }

  &::before {
    @include pseudo;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.3);
    z-index: 1;
  }

  &:focus {
    outline: none;
  }

  &:hover {

    .svg-icon {
      opacity: 1;
    }
  }

  &.swiper-button-disabled {
    opacity: 0;
  }

  @include tablet {
    width: 12rem;
    height: 8rem;
  }
}

.gallery__prev {

  .svg-icon {
    margin-left: -1.4rem;
  }

  @include desktop {
    margin-bottom: 2rem;
  }
}

.gallery__next {
  margin-left: 1rem;

  .svg-icon {
    margin-left: -1.1rem;
  }

  @include desktop {
    margin-left: 2rem;
  }
}

.gallery__navigation-img {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  object-fit: cover;
  pointer-events: none;
}

@media (min-width: 1700px) {

  .gallery {
    width: 146rem;
  }

  .gallery__prev, .gallery__next {
    width: 20rem;
    height: 15rem;

    .svg-icon {
      @include size(3.6rem);
    }
  }

  .gallery__prev {

    .svg-icon {
      margin-left: -1.8rem;
    }
  }

  .gallery__next {
    margin-left: 6rem;

    .svg-icon {
      margin-left: -1.4rem;
    }
  }

  .gallery__navigation {
    left: -11.5rem;
  }
}