/*
row gutters
They help to enlarge or reduce space between columns. They are applied on a row elements. All of them are reseted to default values when a viewport reaches size of a mobile phone.
*/

.gutter-25 {
  margin-right: -15px;
  margin-left: -15px;

  @include widescreen {
    margin-right: -25px;
    margin-left: -25px;
  }

  > .col,
  > [class*="col-"] {
    padding-right: 15px;
    padding-left: 15px;

    @include widescreen {
      padding-right: 25px;
      padding-left: 25px;
    }
  }
}

.gutter-40 {
  margin-right: -15px;
  margin-left: -15px;

  @include widescreen {
    margin-right: -40px;
    margin-left: -40px;
  }

  > .col,
  > [class*="col-"] {
    padding-right: 15px;
    padding-left: 15px;

    @include widescreen {
      padding-right: 40px;
      padding-left: 40px;
    }
  }
}

/*
utility classes
*/

.bg--gray {
  background-color: $gray-color2;
}

.padding-t-1 {
  padding-top: 4rem;

  @include tablet {
    padding-top: 6rem;
  }

  @include widescreen {
    padding-top: 11rem;
  }
}

.padding-t-2 {
  padding-top: 12rem;

  @include tablet {
    padding-top: 18rem;
  }

  @include widescreen {
    padding-top: 34rem;
  }
}

.padding-tb-1 {
  padding-top: 4rem;
  padding-bottom: 4rem;

  @include tablet {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  @include widescreen {
    padding-top: 8rem;
    padding-bottom: 12rem;
  }
}

.padding-tb-3 {
  padding-top: 4rem;
  padding-bottom: 4rem;
  text-align: center;

  @include small {
    text-align: center;
  }

  @include tablet {
    padding-top: 10rem;
    padding-bottom: 0;
    text-align: left;
  }

  @include widescreen {
    padding-top: 12rem;
    padding-bottom: 0;
    text-align: left;
  }
}

.margin-b-1 {
  margin-bottom: 4rem;

  @include tablet {
    margin-bottom: 6rem;
  }

  @include widescreen {
    margin-bottom: 7rem;
  }
}
