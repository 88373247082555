
@font-face {
  font-family: 'PlayfairDisplay';
  src: url('../fonts/PlayfairDisplay-Regular.woff2') format('woff2'),
  url('../fonts/PlayfairDisplay-Regular.woff') format('woff');
  font-weight: normal;
}

@font-face {
  font-family: 'PlayfairDisplay';
  src: url('../fonts/PlayfairDisplay-Italic.woff2') format('woff2'),
  url('../fonts/PlayfairDisplay-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'PlayfairDisplay';
  src: url('../fonts/PlayfairDisplay-Bold.woff2') format('woff2'),
  url('../fonts/PlayfairDisplay-Bold.woff') format('woff');
  font-weight: bold;
}

@font-face {
  font-family: 'PlayfairDisplay';
  src: url('../fonts/PlayfairDisplay-BoldItalic.woff2') format('woff2'),
  url('../fonts/PlayfairDisplay-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

